const config = {
  apiGateway: {
    REGION: 'us-east-1',
    INTERNAL_API_URL: 'https://l5t7i4biwg.execute-api.us-east-1.amazonaws.com/prod',
    TEAM_API_URL: 'https://l42f36xpc9.execute-api.us-east-1.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'hotfix/CV4-2327-oceanagold-add-tagalog-language-to-fid-and-teams',
    HOSTNAME: 'https://teams-service.testing.riotinto.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.riotinto.forwoodsafety.com',
    WEBSOCKET: 'wss://0y3pdsom96.execute-api.us-east-1.amazonaws.com/testing'
  },
};

export default config;
